.App {
  border: 2px solid #d3d3d3;
  border-radius: .5em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;
  padding: 1em;
  text-align: left;
  width: 600px;
  background-color: #f4f5fa;
}
 
.form {
  padding: 1em;
}
 
label {
  font-weight: 600;
}

.body {
  margin: 0;
  padding: 0;
  background-color: #b81c17;
}