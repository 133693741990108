.sidebar{
  width:100%;
  color: #dcdcdc;
  padding-top:20px;
  padding-left:20px;
}
.sidebar__items{
  display: flex;
  flex-direction: column;

}
.sidebar__items a{
  color: #EBF8FE;
  font-size:20px;
  margin-bottom:20px;
  text-decoration: none;
}
.sidebar_active{
  text-decoration: underline!important;
}

.labeltextcolor{
  color:#464855
}

