.header-class{
    background-color: #db3d44;
    color:#fff
}

.evenClass{
    background-color: rgba(245, 247, 250, 0.5);
}

.oddClass{
    background-color: #ffffff;
}
.react-bootstrap-table{
    width:100%;
    overflow-x: scroll;
    padding-top: 2%;
}
.react-bs-table-csv-btn btn btn-default {
    background-color: #3586c1 !important;
}